
const statusList = [
    { id:1, text: 'Pick Requested'},
    { id:2, text: 'Pick Request Cancel'},
    { id:3, text: 'Picked'},
    { id:4, text: 'At Sorting Hub'},
    { id:5, text: 'At Destination Hub'},
    { id:6, text: 'Hub transfer'},
    { id:7, text: 'Rider Assigned'},
    { id:8, text: 'Edited'},
    { id:9, text: 'Hold for Problem'},
    { id:10, text: 'In Transit'},
    { id:11, text: 'On Way'},
    { id:12, text: 'Cancel Request'},
    { id:13, text: 'Partial Request'},
    { id:14, text: 'Delivered'},
    { id:15, text: 'Partial Delivered'},
    { id:16, text: 'Damage/Lost'},
    { id:17, text: 'Hold'},
    { id:18, text: 'Reschedule'},
    { id:19, text: 'Exchanged'},
    { id:20, text: 'Cancel'},
    { id:21, text: 'On way to RTO'},
    { id:22, text: 'Received by RTO'},
    { id:23, text: 'On way to Source HUB'},
    { id:24, text: 'Return Recevied by Source HUB'},
    { id:25, text: 'Return On way to Merchant'},
    { id:26, text: 'Merchant Return Received'},
    { id:27, text: 'Collection Receive'},
    { id:28, text: 'Paid'},
    { id:29, text: 'Deleted'},
    { id:30, text: 'Hub Transfer Request'},
    { id:31, text: 'Ready for RTM'},
    { id:32, text: 'Price Changed'},
    { id:33, text: 'Merchant Changed'},
    { id:34, text: 'Paid'},
    { id:35, text: 'Final Collection Receive'},
    { id:36, text: 'Payment Processing'},
]

const riderStatusList = [
    { id:7, text: 'Rider Assigned'},
    { id:11, text: 'On Way'},
    { id:12, text: 'Cancel Request'},
    { id:13, text: 'Partial Request'},
    { id:14, text: 'Delivered'},
    { id:15, text: 'Partial Delivered'},
    { id:17, text: 'Hold'},
    { id:18, text: 'Reschedule'},
    { id:19, text: 'Exchanged'},
    { id:30, text: 'Hub Transfer Request'},
    { id:31, text: 'Ready for RTM'},
]

const dateParameterList = [
    {
        id: 1,
        text: 'No Date'
    },
    {
        id: 2,
        text: 'Deadline'
    },
    {
        id: 3,
        text: 'Assign Date'
    },
    {
        id: 4,
        text: 'Activity Date'
    },
    {
        id: 5,
        text: 'Last Action'
    }
]

const dateTypeList = [
    {
        id: 1,
        text: 'Today'
    },
    {
        id: 2,
        text: 'Yesterday'
    },
    {
        id: 3,
        text: 'Last 31 days'
    },
    {
        id: 4,
        text: 'Last 6 Month'
    },
    {
        id: 5,
        text: 'Last 1 Year'
    }
]

const paymentByList = [
    {
        id: 1,
        text: 'Bkash'
    },
    {
        id: 2,
        text: 'Rocket'
    },
    {
        id: 3,
        text: 'Nagad'
    },
    {
        id: 4,
        text: 'Bank'
    },
    {
        id: 5,
        text: 'Cash'
    },
    {
        id: 6,
        text: 'Cheque'
    }
]

const categoryList = [
    {
        id: 1,
        text: 'General'
    },
    {
        id: 2,
        text: 'Fragile'
    },
    {
        id: 3,
        text: 'Liquid'
    },
    {
        id: 4,
        text: 'Document'
    },
]

const monthList = [
    {
        id: 1,
        text: 'January'
    },
    {
        id: 2,
        text: 'February'
    },
    {
        id: 3,
        text: 'March'
    },
    {
        id: 4,
        text: 'April'
    },
    {
        id: 5,
        text: 'May'
    },
    {
        id: 6,
        text: 'June'
    },
    {
        id: 7,
        text: 'July'
    },
    {
        id: 8,
        text: 'August'
    },
    {
        id: 9,
        text: 'September'
    },
    {
        id: 10,
        text: 'October'
    },
    {
        id: 11,
        text: 'November'
    },
    {
        id: 12,
        text: 'December'
    }
]

export default {
    statusList,
    riderStatusList,
    dateParameterList,
    dateTypeList,
    paymentByList,
    categoryList,
    monthList
}